import L, { type Polygon } from "leaflet";
import { makeCCW, quickDecomp } from "poly-decomp-es";
import { render } from "preact";
import type { Region, RegionRoute, Regions } from "./Client";
import colours from "./colours.json";
import { RegionModal } from "./components/regions";
import { culture } from "./culture";
import { map } from "./map";
import { RegionChanged, RegionCreated, RegionsReady } from "./setup";
const GetRegionOptions = (): Promise<{
    name: string;
    color: string;
}> => {
    const dialog = document.getElementById(
        "region_options",
    ) as HTMLDialogElement;
    const name_element = dialog.querySelector(
        "[name='name']",
    ) as HTMLInputElement;
    const color_element = dialog.querySelector(
        "[name='color']",
    ) as HTMLInputElement;
    name_element.value = "";
    color_element.value = "#3388ff";
    return new Promise((resolve, reject) => {
        let saved = false;
        dialog.querySelector("button.cancel")?.addEventListener(
            "click",
            () => {
                dialog.close();
            },
            { once: true },
        );
        dialog.addEventListener(
            "close",
            () => {
                if (!saved) {
                    reject();
                }
                dialog.querySelector("form")?.reset();
            },
            { once: true },
        );
        dialog.querySelector("form")?.addEventListener(
            "submit",
            (event) => {
                event.preventDefault();
                saved = true;
                resolve({
                    name: name_element.value,
                    color: color_element.value,
                });
                dialog.close();
            },
            { once: true },
        );
        dialog.showModal();
    });
};
export const regions = new Map<number, Region>();
const pm_create = async ({ layer }: { layer: L.Layer }) => {
    //console.log(layer);

    const polygon = layer as Polygon;
    console.debug(polygon.getLatLngs());
    try {
        const options = await GetRegionOptions();
        //console.debug(options);
        polygon.setStyle({ color: options.color });
        //console.debug(polygon.toGeoJSON().geometry.coordinates);
        const region = processRegion(
            options.name,
            polygon,
            options.color,
            undefined,
            undefined,
        );
        //regions.set(options.name, region);
        if (RegionCreated) {
            RegionCreated(region);
        }
    } catch {
        polygon.remove();
    }
    // const tooltip = document.createElement("div");
    // tooltip.innerHTML = options.name;
    // tooltip.style.setProperty("--color", options.color);
    // tooltip.className = "region-label";
    // polygon.bindTooltip(tooltip, {
    //     permanent: true,
    //     direction: "center",
    // });
    //.openTooltip();
};
const processRegion = (
    name: string,
    polygon: L.Polygon,
    color: string,
    id: number | undefined,
    routes: RegionRoute[] | undefined,
) => {
    const points = polygon.toGeoJSON().geometry.coordinates.flatMap((s) => {
        const vs = s.map((p) => {
            return p.map((ps) => ps as number);
        });
        return vs;
    }) as [number, number][];

    const concavePolygon = points;
    makeCCW(concavePolygon);
    const quickConvexPolygons = quickDecomp(concavePolygon);
    //console.debug(quickConvexPolygons);
    if (quickConvexPolygons) {
        for (const p of quickConvexPolygons) {
            const b = L.polygon(
                p.map((ps) => ps.toReversed() as [number, number]),
            );
            b.setStyle({
                fillColor: "transparent",
                color: "#00FF00",
                opacity: 0.3,
            });
            //console.debug(b);
            //b.addTo(map);
        }
    }
    const region: Region = {
        id: id,
        name: name,
        layer: polygon,
        color: color,
        routes: routes,
        points: points.map((ps) => {
            const point = ps.toReversed() as [number, number];
            return { latitude: point[0], longitude: point[1] };
        }),
        decomp: quickConvexPolygons.map((p) => {
            return p.map((ps) => {
                const point = ps.toReversed() as [number, number];
                return { latitude: point[0], longitude: point[1] };
            });
        }),
    };
    return region;
};
// const pm_edit = async ({ layer }: { layer: L.Layer }) => {
//     const polygon = layer as Polygon;
//     const region = LayerRegionMap.get(polygon);
//     console.debug(region);
//     //polygon.getTooltip()?.openTooltip();
//     const tooltip = polygon.getTooltip();
//     if (tooltip) {
//         polygon.unbindTooltip().bindTooltip(tooltip, {
//             permanent: true,
//             direction: "center",
//         });
//         //.openTooltip();
//     }
// };

export const ReceiveRegions = async (data: Regions) => {
    regions.clear();
    if (map.pm) {
        for (const layer of map.pm.getGeomanLayers()) {
            map.removeLayer(layer);
        }
    }
    //const regions = data.regions;
    for (const r of data.regions) {
        if (r.id) {
            regions.set(r.id, r);
        }
    }

    //map.setZoom(map.getZoom());
    if (data.edit) {
        map.on("pm:create", pm_create);
        render(<RegionModal culture={culture} />, document.body);
        if (!map.pm) {
            await import("@geoman-io/leaflet-geoman-free");
            L.PM.reInitLayer(map);
        }
        map.pm.setGlobalOptions({
            snappable: true,
            allowSelfIntersection: false,
        });
        map.pm.addControls({
            position: "topleft",
            drawMarker: false,
            drawPolyline: false,
            drawCircle: false,
            drawText: false,
            drawCircleMarker: false,
            rotateMode: false,
            dragMode: false,
            cutPolygon: false,
            oneBlock: true,
            drawRectangle: false,
            removalMode: false,
            editMode: false,
        });
    }
    for (const region of regions.values()) {
        const polygon = L.polygon(
            region.points.map((p) => [p.latitude, p.longitude]),
            { color: region.color, pmIgnore: false },
        );
        polygon.addTo(map);
        region.layer = polygon;
        const tooltip = document.createElement("div");
        tooltip.innerHTML = region.name;
        tooltip.style.setProperty("--color", region.color);
        tooltip.className = "region-label";
        polygon.bindTooltip(tooltip, {
            permanent: true,
            direction: "center",
        });
        //.openTooltip();
    }
    RegionsReady();
};
export const EnableDrawRegionMode = () => {
    map.pm.enableDraw("Polygon");
};
export const EnableEditRegionMode = () => {
    map.pm.enableGlobalEditMode();
};
export const CancelEditRegionMode = () => {
    map.pm.disableGlobalEditMode();
};
export const SaveRegionEdits = () => {
    const changedRegions = [];
    for (const oldRegion of regions.values()) {
        const polygon = oldRegion.layer;
        if (!polygon) {
            continue;
        }
        const region = processRegion(
            oldRegion.name,
            polygon,
            polygon.options.color ?? colours[0],
            oldRegion?.id,
            oldRegion?.routes,
        );
        changedRegions.push(region);
    }
    if (RegionChanged) {
        RegionChanged(changedRegions);
    }
    map.pm.disableGlobalEditMode();
};
export const RegionNameChange = ({
    id,
    name,
}: { id: number; name: string }) => {
    const region = regions.get(id);
    if (region?.layer) {
        region.name = name;
        const tooltip = document.createElement("div");
        tooltip.innerHTML = region.name;
        tooltip.style.setProperty("--color", region.color);
        tooltip.className = "region-label";
        region.layer.setTooltipContent(tooltip);
    }
    if (RegionsReady) {
        RegionsReady();
    }
};
export const RegionColorChange = ({
    id,
    color,
}: { id: number; color: string }) => {
    const region = regions.get(id);
    if (region?.layer) {
        region.color = color;
        const tooltip = document.createElement("div");
        tooltip.innerHTML = region.name;
        tooltip.style.setProperty("--color", region.color);
        tooltip.className = "region-label";
        region.layer.setTooltipContent(tooltip);
        region.layer.setStyle({ color: color });
    }
    if (RegionsReady) {
        RegionsReady();
    }
};
export const RegionRoutesChange = ({
    id,
    routes,
}: { id: number; routes: RegionRoute[] }) => {
    const region = regions.get(id);
    if (region) {
        region.routes = routes;
    }
    if (RegionsReady) {
        RegionsReady();
    }
};
export let GenerateRegionFromPoints = async (_data: Region) => {};
if (__REGION_FROM_POINTS) {
    const concaveman = (await import("concaveman")).default;
    const clipperLib = await import("js-angusj-clipper/web");
    const clipper = await clipperLib.loadNativeClipperLibInstanceAsync(
        // let it autodetect which one to use, but also available WasmOnly and AsmJsOnly
        clipperLib.NativeClipperLibRequestedFormat.WasmWithAsmJsFallback,
    );
    GenerateRegionFromPoints = async (data: Region) => {
        const points = data.points.map((p) => [p.latitude, p.longitude]);
        let hull = concaveman(points, Number.POSITIVE_INFINITY).map(
            (ps) => ps as [number, number],
        );
        makeCCW(hull);
        const poly = hull.map((p) => ({
            x: Math.floor(p[0] * 10000),
            y: Math.floor(p[1] * 10000),
        }));
        const d = clipper.offsetToPaths({
            delta: 50,
            offsetInputs: [
                {
                    data: poly,
                    joinType: clipperLib.JoinType.Miter,
                    endType: clipperLib.EndType.ClosedPolygon,
                },
            ],
        });
        if (d) {
            hull = d[0].map((p) => [p.x / 10000, p.y / 10000]);
        }
        const b = L.polygon(hull.map((ps) => ps as [number, number]));
        const options = await GetRegionOptions();
        data.color = options.color;
        data.name = options.name;
        data.layer = b;
        data.points = hull.map((p) => ({ latitude: p[0], longitude: p[1] }));
        //regions.set(data.name, data);
        b.setStyle({
            //fillColor: "transparent",
            color: data.color,
            opacity: 1,
        });
        console.debug(b);
        b.addTo(map);
    };
}
