import L from "leaflet";
import { culture } from "./culture";
import { bingKey, mapBoxKey } from "./keys";
import { setupSearch } from "./search";
import { DarkMode } from "./setup";
export let map: L.Map;
const mapOptions = {
    renderer: L.svg(),
    center: L.latLng(0, 0),
    zoom: 2,
    maxZoom: 23,
    minZoom: 2,
    maxBoundsViscosity: 1,
    worldCopyJump: true,
    closePopupOnClick: false,
    zoomAnimation: false,
    markerZoomAnimation: false,
};
const bingDarkStyle =
    "me|lbc:ffffff;loc:000000_pl|bsc:144b53;boc:00000000_pt|ic:0c4152;fc:000000;sc:0c4152_trs|sc:000000;fc:000000_hg|sc:158399;fc:000000_cah|sc:158399;fc:000000_ard|sc:157399;fc:000000_mr|sc:157399;fc:000000_rl|sc:146474;fc:000000_str|fc:115166_wt|fc:021019_ar|fc:115166_g|lc:0b334d";
let bingLayer: L.BingLayer;
let bingLightLayer: L.BingLayer;
let bingDarkLayer: L.BingLayer;
let bingAerialLayer: L.BingLayer;
let mapboxLayer: L.MapboxLayer;
let mapboxAerialLayer: L.MapboxLayer;
let layerControl: L.Control.Layers;
export const blockedProviders: string[] = [];
export const setupMap = () => {
    map = L.map("app", mapOptions).setView([51.505, -0.09], 13);
    map.setMaxBounds(
        new L.LatLngBounds(L.latLng(-90, -180), L.latLng(90, 180)),
    );
    L.control.scale({ position: "bottomright" }).addTo(map);
    setupSearch(map);

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("telematics")) {
        blockedProviders.push("Bing");
        blockedProviders.push("Bing Aerial");
    }
    setupMapLayers(map);
    //setupZoomHidingOfTooltips();
};
// const tooltipThreshold = 10;
// const setupZoomHidingOfTooltips = () => {
//     let lastZoom: number;
//     map.on("zoomend", () => {
//         const zoom = map.getZoom();
//         if (
//             zoom < tooltipThreshold &&
//             (!lastZoom || lastZoom >= tooltipThreshold)
//         ) {
//             map.eachLayer((l) => {
//                 const polygon = l as Polygon;
//                 if (polygon) {
//                     const tooltip = polygon.getTooltip();
//                     if (tooltip) {
//                         //tooltip.closeTooltip();
//                         //polygon.unbindTooltip();
//                         polygon.bindTooltip(tooltip, {
//                             permanent: false,
//                         });
//                     }
//                 }
//             });
//         } else if (
//             zoom >= tooltipThreshold &&
//             (!lastZoom || lastZoom < tooltipThreshold)
//         ) {
//             map.eachLayer((l) => {
//                 const polygon = l as Polygon;
//                 if (polygon) {
//                     const tooltip = polygon.getTooltip();
//                     if (tooltip) {
//                         polygon.unbindTooltip();
//                         //polygon.unbindTooltip();
//                         polygon.bindTooltip(tooltip, {
//                             permanent: true,
//                             direction: "center",
//                         });
//                         //.openTooltip();
//                     }
//                 }
//             });
//         }
//         lastZoom = zoom;
//     });
// };
const setupBingLayer = (darkMode: boolean | undefined) => {
    if (darkMode) {
        if (!bingDarkLayer) {
            bingDarkLayer = L.bingLayer(bingKey, {
                imagerySet: "RoadOnDemand",
                culture: culture,
                style: bingDarkStyle,
                maxZoom: 21,
            });
        }
        bingLayer = bingDarkLayer;
    } else {
        if (!bingLightLayer) {
            bingLightLayer = L.bingLayer(bingKey, {
                imagerySet: "RoadOnDemand",
                culture: culture,
                maxZoom: 21,
            });
        }
        bingLayer = bingLightLayer;
    }
};
const setupBingAerialLayer = () => {
    if (!bingAerialLayer) {
        bingAerialLayer = L.bingLayer(bingKey, {
            imagerySet: "AerialWithLabelsOnDemand",
            culture: culture,
            maxZoom: 20,
        });
    }
};
const setupMapboxAerialLayer = () => {
    if (!mapboxAerialLayer) {
        mapboxAerialLayer = L.mapboxGL({
            accessToken: mapBoxKey,
            style: "mapbox://styles/mcscloud/cllkx9a1g01ch01p800b0ecpe",
            attribution: "© Mapbox © OpenStreetMap",
            maxZoom: 23,
        });
    }
};
const setupMapboxLayer = () => {
    if (!mapboxLayer) {
        mapboxLayer = L.mapboxGL({
            accessToken: mapBoxKey,
            style: "mapbox://styles/mcscloud/cllkr18sk01cz01qsgpkndd31",
            attribution: "© Mapbox © OpenStreetMap",
            maxZoom: 23,
        });
    }
};

export const setupMapLayers = (map: L.Map) => {
    const darkMode = DarkMode;

    if (!blockedProviders.includes("Bing")) {
        setupBingLayer(darkMode);
    }
    if (!blockedProviders.includes("Bing Aerial")) {
        setupBingAerialLayer();
    }
    if (!blockedProviders.includes("Mapbox Aerial")) {
        setupMapboxAerialLayer();
    }
    if (!blockedProviders.includes("Mapbox")) {
        setupMapboxLayer();
    }
    if (layerControl) {
        map.removeControl(layerControl);
    }
    const layers: Record<string, L.Layer> = {
        Bing: bingLayer,
        "Bing Aerial": bingAerialLayer,
        Mapbox: mapboxLayer,
        "Mapbox Aerial": mapboxAerialLayer,
    };
    const filteredLayers = getFilteredLayers(layers);
    layerControl = L.control.layers(filteredLayers);
    layerControl.addTo(map);
    for (const layer of Object.values(layers)) {
        if (layer && map.hasLayer(layer)) {
            map.removeLayer(layer);
        }
    }
    if (bingDarkLayer) {
        map.removeLayer(bingDarkLayer);
    }
    if (bingLightLayer) {
        map.removeLayer(bingLightLayer);
    }
    const defaultLayer = Object.values(filteredLayers)[0] as L.TileLayer;
    defaultLayer.addTo(map);
    map.setMaxZoom(defaultLayer.options.maxZoom ?? 1);
    document.addEventListener("change", (event) => {
        const target = event.target as HTMLInputElement;
        if (target.classList.contains("leaflet-control-layers-selector")) {
            const current_layer = Object.values(filteredLayers).filter(
                (layer) => map.hasLayer(layer as L.Layer),
            )[0] as L.TileLayer;
            if (current_layer) {
                map.setMaxZoom(current_layer.options.maxZoom ?? 1);
            }
        }
    });
};
const getFilteredLayers = (layers: Record<string, L.Layer>) => {
    return Object.keys(layers)
        .filter((key) => !blockedProviders.includes(key))
        .reduce(
            (obj, key) => {
                obj[key] = layers[key];
                return obj;
            },
            {} as Record<string, L.Layer>,
        );
};
