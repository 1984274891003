import {
    type Location,
    type Request,
    type Result,
    ResultFromRequest,
} from "$lib/Client.ts";
import colours from "$lib/colours.json";
import {
    DivIcon,
    type MarkerClusterGroupOptions,
    Point,
    latLng,
    latLngBounds,
    polyline,
} from "leaflet";
import { MarkerClusterGroup } from "leaflet.markercluster";
import { setCulture } from "./culture";
import { map, setupMapLayers } from "./map";
import { markerForLocation } from "./marker";
import { setupSearch } from "./search";
import { RouteColours } from "./setup";
const routeCluster = (routeId: string, routeIndex: number) => {
    const colour =
        routeId === "-1" ? "#000000" : colours[routeIndex % colours.length];
    return new MarkerClusterGroup({
        maxClusterRadius: 80,
        iconCreateFunction: (cluster) => {
            const childCount = cluster.getChildCount();
            return new DivIcon({
                html: `<div style="--color:${colour}"><span>${childCount}</span></div>`,
                className: "route-marker-cluster",
                iconSize: new Point(40, 40),
            });
        },
    } as MarkerClusterGroupOptions);
};
const removeExistingClusters = () => {
    map.eachLayer((l) => {
        if (l instanceof MarkerClusterGroup) {
            map.removeLayer(l);
        }
    });
};
export const RecieveData = (request: Request) => {
    const data = ResultFromRequest(request);
    const routes = new Map<string, Location[]>(Object.entries(data.routes));
    const routeColours = new Map<string, string>();
    removeExistingClusters();
    setCulture(data.culture);
    setupSearch(map);
    setupMapLayers(map);
    let routeIndex = 0;
    const markers = [];
    const focus = [];
    let clusterGroup: MarkerClusterGroup;
    if (!__ROUTE_CLUSTERS) {
        clusterGroup = new MarkerClusterGroup({ maxClusterRadius: 0 });
        map.addLayer(clusterGroup);
    }
    for (const entry of routes) {
        const routeId = entry[0];
        if (__ROUTE_CLUSTERS) {
            clusterGroup = routeCluster(routeId, routeIndex);
            map.addLayer(clusterGroup);
        }
        const route: Location[] = entry[1];
        route.sort((a: Location, b: Location) => a.order - b.order);
        const hasTerminator = route.some((l) => l.showAsTerminator);
        for (const loc of route) {
            const marker = markerForLocation(
                route,
                loc,
                routeIndex,
                hasTerminator,
                routeColours,
            );
            markers.push(latLng(loc.latitude, loc.longitude));
            if (loc.focus) {
                focus.push(latLng(loc.latitude, loc.longitude));
            }
            marker?.addTo(clusterGroup ?? map);
        }

        if (routeId !== "-1") {
            routeIndex++;
        }
    }
    const bounds = latLngBounds(focus.length ? focus : markers);
    map.fitBounds(bounds, { maxZoom: 17 });
    if (RouteColours) {
        RouteColours(routeColours);
    }
    drawPath(data, routeColours);
};
const drawPath = (data: Result, routeColours: Map<string, string>) => {
    if (data.path) {
        for (const path of Object.entries(data.path)) {
            const line = polyline(path[1], {
                color: routeColours.get(path[0]),
            });
            line.addTo(map);
        }
    }
};
